import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import logo from "../images/logo.png";
import whatsapp from "../images/whatsapp.png";
import facebook from "../images/facebook.png"
import instagram from "../images/instagram.png"

import '../styles/RecuperacionPass.css'

axios.defaults.baseURL = 'http://oliviawilsoncitas.online'; 

function RecuperacionPass() {
  const [step, setStep] = useState(1);
  const [email, setEmail] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [message, setMessage] = useState('');
  const navigate = useNavigate();

  const handleSendCode = async () => {
    try {
      setStep(2);
      setMessage('El código puede demorar hasta un minuto en llegar. Verifique en la carpeta de spam.');
      await axios.post('/api/recuperacion/send-code', { email });
    } catch (error) {
      console.error('Error sending verification code:', error);
      alert('Error al enviar el código de verificación');
    }
  };
  
  const handleResendCode = async () => {
    setMessage('Nuevo código de verificación enviado a tu correo electrónico.');
    setTimeout(() => setMessage(''), 3000); // El mensaje desaparece después de 3 segundos

    try {
      const response = await axios.post('/api/recuperacion/resend-code', { email });
      setMessage(response.data.message);
      setTimeout(() => setMessage(''), 3000); // Vuelve a ocultar el mensaje después de 3 segundos
    } catch (error) {
      console.error('Error re-sending verification code:', error);
      setMessage('Hubo un error al enviar el código. Inténtalo de nuevo.');
      setTimeout(() => setMessage(''), 3000); // Vuelve a ocultar el mensaje después de 3 segundos
    }
  };

  const handleVerifyCode = async () => {
    try {
      await axios.post('/api/recuperacion/verify-code', { email, verificationCode });
      setStep(3);
    } catch (error) {
      console.error('Error verifying code:', error);
      alert('Código de verificación incorrecto');
    }
  };
  
  const handleResetPassword = async () => {
    if (newPassword !== confirmPassword) {
      alert('Las contraseñas no coinciden');
      return;
    }
    try {
      const response = await axios.post('/api/recuperacion/update-password', {
        email,
        newPassword,
        confirmPassword
      });
      console.log('Response data:', response.data); // Añadir este log para depuración
      alert('Contraseña restablecida con éxito');
      navigate('/auth');
    } catch (error) {
      console.error('Error resetting password:', error);
      alert('Error al restablecer la contraseña');
    }
  };

  return (
    <div className="container-recuperacion-one">
      <div className="header">
        <img src={logo} alt="Logo" className="logo" />
        <h1>Agendamiento de Citas Palo Rosa</h1>
      </div>
      <div className="welcome-banner">
        <h2>Recuperación de Contraseña</h2>
        <p>No te preocupes, estamos aquí para ayudarte a recuperar el acceso a tu cuenta. Sigue los pasos a continuación y pronto tendrás acceso de nuevo.</p>
      </div>

      <div className="container-recuperacion">
        {step === 1 && (
          <div className="form-recuperacion">
            <label htmlFor="email">Correo Electrónico</label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <button className="button-recuperacion" onClick={handleSendCode}>Enviar Código</button>
          </div>
        )}
        {step === 2 && (
          <div className="form-recuperacion">
            <label htmlFor="verificationCode">Código de Verificación</label>
            <input
              type="text"
              id="verificationCode"
              value={verificationCode}
              onChange={(e) => setVerificationCode(e.target.value)}
              required
            />
            <button className="button-recuperacion" onClick={handleVerifyCode}>Verificar Código</button>
            <p className="message">{message}</p>
            <p className="resend-code" onClick={handleResendCode}>
              ¿No recibiste el código? <span className="underline">Haz clic aquí para reenviar</span>
            </p>
          </div>
        )}
        {step === 3 && (
          <div className="form-recuperacion">
            <label htmlFor="newPassword">Nueva Contraseña</label>
            <input
              type="password"
              id="newPassword"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              required
            />
            <label htmlFor="confirmPassword">Confirmar Nueva Contraseña</label>
            <input
              type="password"
              id="confirmPassword"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
            />
            <button className="button-recuperacion" onClick={handleResetPassword}>Restablecer Contraseña</button>
          </div>
        )}
      </div>
      <div className="footer">
  <p>Estamos aquí para hacerte sentir más hermosa y cuidada.</p>
  <div className="footer-menu">
    <div className="footer-option">
      <a href="https://www.instagram.com/palo_rosaarauca?igsh=MXNmMjBmd2tnajhsMw==" target="_blank" rel="noopener noreferrer">
        <img src={instagram} alt="Instagram" />
        <p>Instagram</p>
      </a>
    </div>
    <div className="footer-option">
      <a href="https://www.facebook.com/profile.php?id=100084094834817&mibextid=ZbWKwL" target="_blank" rel="noopener noreferrer">
        <img src={facebook} alt="Facebook" />
        <p>Facebook</p>
      </a>
    </div>
    <div className="footer-option">
      <a href="https://wa.me/+573219835941" target="_blank" rel="noopener noreferrer">
        <img src={whatsapp} alt="WhatsApp" />
        <p>WhatsApp</p>
      </a>
    </div>
  </div>
</div>
      <div className="credits">
        Esta página fue creada por <a href="mailto:medellincodestudios@gmail.com">Medellin Code Studios</a>
      </div>
    </div>
  );
}

export default RecuperacionPass;







