import React, { useState } from 'react';
import ManageWorkers from './ManageWorkers';
import ManageAppointments from './ManageAppointments';
import '../styles/AdminPanel.css';
import logo from "../images/logo.png";

function AdminPanel() {
  const [view, setView] = useState('appointments');

  return (
    <div className="admin-panel-contenedor">
      <div className="header">
        <img src={logo} alt="Logo" className="logo" />
        <h1>Agendamiento de Citas Palo Rosa</h1>
      </div>
      <div className="welcome-banner">
  <h2>Panel de Administración</h2>
  <p>Aquí podrás ver las citas agendadas, revisar los datos de los clientes y gestionar la cantidad de trabajadores. Todo lo necesario para una administración eficiente, directamente desde esta sección.</p>
</div>

      <div className="admin-panel">
      <h2 className='panel-administri'>Panel de Administración</h2>
      <div className="navigation-buttons">
        <button onClick={() => setView('appointments')}>Citas</button>
        <button onClick={() => setView('workers')}>Trabajadores</button>
      </div>
      <div className="sections">
        {view === 'appointments' && <ManageAppointments />}
        {view === 'workers' && <ManageWorkers />}
      </div>
    </div>
    <footer className="panel-footer">
  <div className="footer-content">
    <p>&copy; 2024 Medellín Code Studios</p>
    <p>Correo electrónico: <a href="mailto:medellincodestudios@gmail.com">info@medellincodestudios.com</a></p>
    <div className="footer-icons">
      <a href="mailto:medellincodestudios@gmail.com" aria-label="Correo electrónico">
        <i className="fa fa-envelope"></i>
      </a>

    </div>
  </div>
</footer>

    </div>
  );
}

export default AdminPanel;





















