import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import '../styles/Verify.css';
import logo from "../images/logo.png";

const Verify = () => {
  const [verificationCode, setVerificationCode] = useState('');
  const [message, setMessage] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const { email } = location.state || {};

  useEffect(() => {
    if (!email) {
      navigate('/register');
    }
  }, [email, navigate]);

  const handleVerify = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('http://oliviawilsoncitas.online/api/users/verify', { email, verification_code: verificationCode });
      alert(response.data.message);
      if (response.data.message === 'El correo electrónico ha sido verificado exitosamente.') {
        navigate('/user-details', { state: { userId: response.data.userId } });
      }
    } catch (error) {
      console.error(error);
      alert('El código de verificación ingresado es incorrecto.');
    }
  };

  const handleResendCode = async () => {
    setMessage('Nuevo código de verificación enviado a tu correo electrónico.');
    setTimeout(() => setMessage(''), 3000); // El mensaje desaparece después de 3 segundos

    try {
      const response = await axios.post('http://oliviawilsoncitas.online/api/users/resend-code', { email });
      setMessage(response.data.message);
      setTimeout(() => setMessage(''), 3000); // Vuelve a ocultar el mensaje después de 3 segundos
    } catch (error) {
      console.error(error);
      setMessage('Hubo un error al enviar el código. Inténtalo de nuevo.');
      setTimeout(() => setMessage(''), 3000); // Vuelve a ocultar el mensaje después de 3 segundos
    }
  };

  return (
    <div className="container-verify-one-one">
      <div className="header">
        <img src={logo} alt="Logo" className="logo" /> 
        <h1>Agendamiento de Citas Palo Rosa</h1>
      </div>
      <div className="container-verify">
        <form className="verify-form" onSubmit={handleVerify}>
          <h2 className="verify-title">Verificación de Email</h2>
          <div className="verify-input-container">
            <label className="verify-label">Email:</label>
            <input
              type="email"
              value={email}
              readOnly
              className="verify-input"
            />
          </div>
          <div className="verify-input-container">
            <label className="verify-label">Código de verificación:</label>
            <input
              type="text"
              value={verificationCode}
              onChange={(e) => setVerificationCode(e.target.value)}
              required
              className="verify-input"
            />
          </div>
          <button type="submit" className="verify-button">
            Verificar
          </button>
          <p className='demora'>La entrega de este código puede tardar hasta un minuto en llegar.</p>
          <p className='resend-code' onClick={handleResendCode}>
            ¿No recibiste el código? <span className="underline">Haz clic aquí para reenviar</span>
          </p>
          {message && <p className='message'>{message}</p>}
        </form>
      </div>
    </div>
  );
};

export default Verify;








