import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';
import '../styles/VisualizarCitas.css'
import logo from "../images/logo.png";
import whatsapp from "../images/whatsapp.png";
import facebook from "../images/facebook.png"
import instagram from "../images/instagram.png"

function VisualizarCitas() {
  const [appointments, setAppointments] = useState([]);
  const [services, setServices] = useState({});
  const navigate = useNavigate();
  const location = useLocation();
  const { userId } = location.state || {};

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user'));
    if (!user) {
      navigate('/login-visualizar');
      return;
    }

    const fetchAppointments = async () => {
      try {
        const response = await axios.get(`/api/appointments?userId=${userId}`);
        setAppointments(response.data);
      } catch (error) {
        console.error('Error al obtener citas:', error);
        alert('Error al obtener citas');
      }
    };

    const fetchServices = async () => {
      try {
        const response = await axios.get('/api/services'); // Supongamos que tienes un endpoint para obtener servicios
        const servicesMap = response.data.reduce((acc, service) => {
          acc[service.id] = service.nombre;
          return acc;
        }, {});
        setServices(servicesMap);
      } catch (error) {
        console.error('Error al obtener servicios:', error);
        alert('Error al obtener servicios');
      }
    };

    if (userId) {
      fetchAppointments();
      fetchServices();
    } else {
      navigate('/login-visualizar');
    }
  }, [navigate, userId]);

  const handleCancel = async (appointmentId) => {
    try {
      await axios.delete(`/api/appointments/${appointmentId}`);
      setAppointments((prev) => prev.filter((appt) => appt.id !== appointmentId));
      alert('Cita cancelada exitosamente');
    } catch (error) {
      console.error('Error al cancelar cita:', error);
      alert('Error al cancelar cita');
    }
  };

  const formatDateTime = (dateTimeString) => {
    const options = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    };
    return new Date(dateTimeString).toLocaleDateString('es-ES', options);
  };

  return (
    <div className='visualizar-principal'>
      <div className="header">
        <img src={logo} alt="Logo" className="logo" />
        <h1>Agendamiento de Citas Palo Rosa</h1>
      </div>
      <div className="welcome-banner">
        <h2>Gestiona tus citas fácilmente</h2>
        <p>Aquí podrás ver todas tus citas programadas y cancelarlas si es necesario. Gestiona tus citas de manera sencilla y conveniente desde esta sección.</p>
      </div>

      <div className="manage-appointments">
      <h2>Tus Citas</h2>
      {appointments.length === 0 ? (
        <p>No tienes citas programadas</p>
      ) : (
        <ul>
          {appointments.map((appointment) => (
            <li key={appointment.id}>
              <p>
                {formatDateTime(appointment.fecha_hora)} - {services[appointment.servicio_id] || 'Servicio desconocido'}
              </p>
              <button onClick={() => handleCancel(appointment.id)}>Cancelar</button>
            </li>
          ))}
        </ul>
      )}
    </div>
    <div className="footer">
  <p>Estamos aquí para hacerte sentir más hermosa y cuidada.</p>
  <div className="footer-menu">
    <div className="footer-option">
      <a href="https://www.instagram.com/palo_rosaarauca?igsh=MXNmMjBmd2tnajhsMw==" target="_blank" rel="noopener noreferrer">
        <img src={instagram} alt="Instagram" />
        <p>Instagram</p>
      </a>
    </div>
    <div className="footer-option">
      <a href="https://www.facebook.com/profile.php?id=100084094834817&mibextid=ZbWKwL" target="_blank" rel="noopener noreferrer">
        <img src={facebook} alt="Facebook" />
        <p>Facebook</p>
      </a>
    </div>
    <div className="footer-option">
      <a href="https://wa.me/+573219835941" target="_blank" rel="noopener noreferrer">
        <img src={whatsapp} alt="WhatsApp" />
        <p>WhatsApp</p>
      </a>
    </div>
  </div>
</div>
      <div className="credits">
        Esta página fue creada por <a href="mailto:medellincodestudios@gmail.com">Medellin Code Studios</a>
      </div>
    </div>
  );
}

export default VisualizarCitas;







