import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import '../styles/Register.css';
import logo from "../images/logo.png";
import whatsapp from "../images/whatsapp.png";
import facebook from "../images/facebook.png"
import instagram from "../images/instagram.png"

const Register = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleRegister = async (e) => {
    e.preventDefault();

    // Validar que las contraseñas coincidan
    if (password !== confirmPassword) {
      setError('Las contraseñas no coinciden');
      return;
    }

    // Validar que la contraseña cumpla con los requisitos de complejidad
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{7,}$/;
    if (!passwordRegex.test(password)) {
      setError('La contraseña debe tener al menos 7 caracteres, una letra mayúscula, una letra minúscula y un número');
      return;
    }

    try {
      const response = await axios.post('http://oliviawilsoncitas.online/api/users/register', {  
        username,
        password,
        email,
      });

      alert(response.data.message);
      navigate('/verify', { state: { email } });
    } catch (error) {
      console.error(error);
      if (error.response && error.response.data) {
        setError(`Falló el registro: ${error.response.data.message}`);
      } else {
        setError('Falló el registro');
      }
    }
  };

  return (
    <div className="container-register-one">
      <div className="header">
        <img src={logo} alt="Logo" className="logo" /> 
        <h1>Agendamiento de Citas Palo Rosa</h1>
      </div>
      <div className="welcome-banner">
        <h2>Acceso necesario para agendar citas</h2>
        <p>Para escoger un servicio y agendar tu cita, por favor inicia sesión o regístrate.</p>
      </div>
      <div className="container-register">
        <form className="form-register" onSubmit={handleRegister}>
          <h2 className="form-title-register">Registro De Usuario</h2>
          {error && <div className="alert">{error}</div>}
          <input 
            className="input-register" 
            type="text" 
            placeholder="Nombre de usuario" 
            value={username} 
            onChange={(e) => setUsername(e.target.value)} 
            required 
          />
          <input 
            className="input-register" 
            type="password" 
            placeholder="Contraseña" 
            value={password} 
            onChange={(e) => setPassword(e.target.value)} 
            required 
          />
          <input 
            className="input-register" 
            type="password" 
            placeholder="Confirmar Contraseña" 
            value={confirmPassword} 
            onChange={(e) => setConfirmPassword(e.target.value)} 
            required 
          />
          <input 
            className="input-register" 
            type="email" 
            placeholder="Correo Electrónico" 
            value={email} 
            onChange={(e) => setEmail(e.target.value)} 
            required 
          />
          <button className="button-register" type="submit">Registrate</button>
          <div className="login-redirect">
            <p className="login-question">¿Ya tienes cuenta? <span onClick={() => navigate('/auth')} className="login-link">Inicia sesión aquí</span></p>
          </div>

        </form>
        
      </div>
      <div className="footer">
  <p>Estamos aquí para hacerte sentir más hermosa y cuidada.</p>
  <div className="footer-menu">
    <div className="footer-option">
      <a href="https://www.instagram.com/palo_rosaarauca?igsh=MXNmMjBmd2tnajhsMw==" target="_blank" rel="noopener noreferrer">
        <img src={instagram} alt="Instagram" />
        <p>Instagram</p>
      </a>
    </div>
    <div className="footer-option">
      <a href="https://www.facebook.com/profile.php?id=100084094834817&mibextid=ZbWKwL" target="_blank" rel="noopener noreferrer">
        <img src={facebook} alt="Facebook" />
        <p>Facebook</p>
      </a>
    </div>
    <div className="footer-option">
      <a href="https://wa.me/+573219835941" target="_blank" rel="noopener noreferrer">
        <img src={whatsapp} alt="WhatsApp" />
        <p>WhatsApp</p>
      </a>
    </div>
  </div>
</div>
      <div className="credits">
        Esta página fue creada por <a href="mailto:medellincodestudios@gmail.com">Medellin Code Studios</a>
      </div>
    </div>
  );
};

export default Register;
















